import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col p-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_skeleton = _resolveComponent("skeleton")!
  const _component_ColumnCharts = _resolveComponent("ColumnCharts")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "px-3 py-4 flex flex-col" }, {
    default: _withCtx(() => [
      _createVNode(_component_TooltipV2, {
        text: _ctx.title,
        fontSize: 14,
        fontWeight: "semibold"
      }, null, 8, ["text"]),
      (_ctx.dashboardData.loading)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode(_component_skeleton, {
              width: "100%",
              height: "15rem"
            })
          ]))
        : (_openBlock(), _createBlock(_component_ColumnCharts, _mergeProps({ key: 1 }, _ctx.backlogCharts), null, 16))
    ]),
    _: 1
  }))
}