
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Options, Vue } from "vue-class-component";
import SectionTotalTicket from "../components/section-total-ticket.vue";
import SectionBacklog from "../components/section-backlog.vue";
import SectionBreakdownTicketDexDispute from "../components/section-breakdown-ticket-dex-dispute.vue";
@Options({
  components: {
    SectionBacklog,
    SectionTotalTicket,
    SectionBreakdownTicketDexDispute
  }
})
export default class DexDisputeL2Tab extends Vue {
  get title(): string {
    return (
      DexAssessmentController.dashboardTabs.find(
        e => e.path === DexAssessmentController.dashboardCurrentTabActive
      )?.title || ""
    );
  }
  // date
  get filter() {
    return DexAssessmentController.filter;
  }

  async setDateRange(value: any[]) {
    const totalTicketDispute: any = this.$refs["total-ticket-dispute"];
    const percentageTicketDispute: any = this.$refs[
      "percentage-ticket-dispute"
    ];
    const backlogDispute: any = this.$refs["backlog-dispute"];
    const breakdownTicket: any = this.$refs["breakdown-ticket"];
    DexAssessmentController.setStartDate(value[0]);
    DexAssessmentController.setEndDate(value[1]);
    const validDate = value[0] && value[1];
    if (validDate) {
      totalTicketDispute.fetchData();
      percentageTicketDispute.fetchData();
      backlogDispute.fetchData();
      breakdownTicket.onFetchSummaryBreakdownTicketDex();
    }
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();

  onDownload(endpoint: string) {
    DexAssessmentController.filter.type = "dispute";
    DexAssessmentController.getDownload(endpoint);
  }
}
