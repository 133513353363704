import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "balance-list bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full font-montserrat" }
const _hoisted_2 = {
  class: "overflow-auto pb-8",
  style: {"height":"100vh"}
}
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "mt-8 flex flex-wrap items-center justify-between" }
const _hoisted_5 = { class: "flex flex-wrap items-center gap-4" }
const _hoisted_6 = { class: "p-4 flex flex-col gap-4" }
const _hoisted_7 = { class: "flex flex-row gap-4 justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_SectionTotalTicket = _resolveComponent("SectionTotalTicket")!
  const _component_SectionBacklog = _resolveComponent("SectionBacklog")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SectionBreakdownTicketDexDispute = _resolveComponent("SectionBreakdownTicketDexDispute")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Title, { label: _ctx.title }, null, 8, ["label"]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_time_range_picker, {
              startDate: _ctx.filter.startDate,
              endDate: _ctx.filter.endDate,
              onSelect: _ctx.setDateRange,
              default: "hari ini",
              isCloseDateAfterSelect: "",
              leftPosition: false,
              maxDate: new Date()
            }, null, 8, ["startDate", "endDate", "onSelect", "maxDate"])
          ])
        ]),
        _createVNode(_component_Card, { class: "mr-1" }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_6, [
              _createVNode("div", _hoisted_7, [
                _createVNode(_component_Title, { label: "Ringkasan Tiket DEX Sanggah L2" }),
                _createVNode(_component_lp_button, {
                  icon: "download-new",
                  outline: "",
                  small: "",
                  onClick: _cache[1] || (_cache[1] = () => _ctx.onDownload('summary-ticket-cs/download'))
                })
              ]),
              _createVNode(_component_SectionTotalTicket, {
                title: "Total Tiket DEX Sanggah L2",
                loading: _ctx.dashboardData.loading,
                ref: "total-ticket-dispute",
                type: "dispute"
              }, null, 8, ["loading"]),
              _createVNode(_component_SectionBacklog, {
                title: "Backlog Tiket DEX Sanggah L2 Per Hari",
                type: "dispute",
                ref: "backlog-dispute"
              }, null, 512),
              _createVNode(_component_SectionTotalTicket, {
                title: "Persentase Tiket DEX Sanggah L2",
                loading: _ctx.dashboardData.loading,
                ref: "percentage-ticket-dispute",
                type: "percentage-dispute",
                showSubData: false,
                leftClass: "w-full"
              }, null, 8, ["loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_SectionBreakdownTicketDexDispute, { ref: "breakdown-ticket" }, null, 512)
      ])
    ])
  ]))
}