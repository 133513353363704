
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { Vue, prop } from "vue-class-component";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { SummaryBacklog } from "@/domain/entities/DexAssessment";
class Props {
  title = prop<string>({
    default: "",
    type: String
  });
  type = prop<string>({
    default: "l1" || "l2",
    type: String
  });
}
export default class DexAssesmentTab extends Vue.with(Props) {
  mounted(): void {
    this.fetchData();
  }
  backlogCharts = {
    loading: false,
    groupedSeriesData: [] as {
      name: string;
      data: any[];
    }[],
    categories: [] as string[],
    rounded: false,
    legend: true,
    barLabel: true,
    xAxisFontSize: "12px",
    yAxisFontSize: "12px",
    barColumnWidth: "75%",
    groupedColor: ["#58C5E2", "#ECB888"],
    tooltip: true
  };
  // date
  get filter() {
    return DexAssessmentController.filter;
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.dashboardData.loading = true;
      const {
        data: summaryBacklog
      } = await DexAssessmentController.getSummaryBacklog(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          ticketType: this.type
        })
      );
      this.onMappingSummaryBacklog(summaryBacklog);
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }
  onMappingSummaryBacklog(data: SummaryBacklog): void {
    this.backlogCharts = {
      ...this.backlogCharts,
      categories: data.labels,
      groupedSeriesData: data.datasets.map((item: any) => ({
        name: item.label,
        data: item.data
      }))
    };
  }
}
